import React, { Component } from 'react';
import Text from './text';
import styles from '../styles/scss/components/radiobutton.module.scss';
import cn from 'classnames';

export default class Index extends Component {
    state = {
        stateValue: false,
    };
    handleClick(e) {
        const { onClick } = this.props;
        const { stateValue } = this.state;
        if (e.key !== "Tab" && e.key !== "Shift") {
            console.log('ff', e.key)
            this.setState({ stateValue: !stateValue }, () => {
                if (onClick) onClick(this.state.stateValue);
            });
        }
    }
    render() {
        const { value, name, text, style, className, onChange } = this.props;
        const { stateValue } = this.state;
        return (
            <div 
                role='button' 
                tabIndex={0} 
                className={cn(styles.container, className)} 
                style={style} 
                onKeyDown={this.handleClick.bind(this)} 
                onClick={this.handleClick.bind(this)}>
                <input type='radio' name={name} onChange={onChange} value={value || stateValue} />
                {text && <Text value={text} className={styles.title} />}
            </div>
        );
    }
}
