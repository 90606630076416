import React, { useState } from "react"
import Title from "../components/title"
import Input from "../components/input"
import Text from "../components/text"
import RadioButton from "../components/radiobutton"
import Imgix from "react-imgix"
import Button from "../components/button"
import { Formik } from "formik"
import { isArray as _isArray } from "lodash"
import { signUp } from "../services/email"
import SEO from "../components/seo"
import cn from "classnames"

import styles from "../styles/scss/templates/techsandtradeprogram.module.scss"
import NewLayout from "../layout/newLayout"

const TechAndTradeProgram = ({ pageContext: { page } }) => {
    const [showConfirmation, setShowConfirmation] = useState(false)

    return (
        <NewLayout>
            <SEO page={page} />
            <div
                className={
                    showConfirmation
                        ? styles.thankYouPageContainer
                        : styles.pageContainer
                }
                style={{
                    backgroundImage: `url(${page.heroImage.url})`
                }}
            >
                <div className={styles.heroContainer}>
                    <div className={styles.logoContainer}>
                        <a href="/techs-and-trades/">
                            <Imgix
                                src="/img/techsandtrade/ttlogo.svg"
                                htmlAttributes={{ alt: "WD-40 Techs & Trade Logo" }}
                            />
                        </a>
                    </div>
                </div>
                {showConfirmation ? (
                    <ConfirmationSection />
                ) : (
                    <SignupFormSection setShowConfirmation={setShowConfirmation} />
                )}
            </div>
        </NewLayout>
    )
}

const ConfirmationSection = () => (
    <div className={styles.confirmWhiteSection}>
        <div
            className={
                styles.confirmWhiteSection__confirmContentWrapper
            }
        >
            <Title
                className={
                    styles.confirmWhiteSection__contentWrapper__confirmTitle
                }
                value={"THANK YOU!"}
            />
            <br />
            <Text
                className={
                    styles.confirmWhiteSection__contentWrapper__bodyText
                }
            >
                Your information has been submitted. Thanks! A
                confirmation email is on its way to your email
                address and should arrive shortly.
            </Text>
            <br />
            <Text
                className={
                    styles.confirmWhiteSection__contentWrapper__bodyText
                }
            >
                Note: To ensure proper delivery of our emails,
                take a moment now and add us,{" "}
                <Text
                    className={
                        styles.confirmWhiteSection__contentWrapper__bodyText__anchor
                    }
                >
                    no-reply@wd40company.com
                </Text>
                , to your address book, trusted sender list, or
                company white list.
            </Text>
            <a
                href="/techs-and-trades/"
                className={
                    styles.confirmWhiteSection__contentWrapper__anchor
                }
            >
                <i class="fa fa-chevron-left"></i> Back to the page
            </a>
        </div>
    </div>
)

const SignupFormSection = ({ setShowConfirmation }) => (
    <div className={styles.whiteSection}>
        <div className={styles.whiteSection__contentWrapper}>
            <Title
                className={
                    styles.whiteSection__contentWrapper__title
                }
                value={"Join the techs and trades program"}
            />
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    industry: "",
                    industrytime: "",
                    userplus18yearsold: "",
                }}
                validate={values => {
                    var errors = []
                    //
                    if (values.firstName === "") {
                        errors.push({
                            field: "firstName",
                            message: "First Name is required",
                        })
                    }
                    //
                    if (values.lastName === "") {
                        errors.push({
                            field: "lastName",
                            message: "Last Name is required",
                        })
                    }

                    //
                    if (values.email === "") {
                        errors.push({
                            field: "email",
                            message: "Email is required",
                        })
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                        )
                    ) {
                        errors.push({
                            field: "email",
                            message: "Invalid email address",
                        })
                    }

                    if (values.phone === "") {
                        errors.push({
                            field: "phone",
                            message: "Mobile Phone Number is required",
                        })
                    }

                    //
                    if (values.industry === "") {
                        errors.push({
                            field: "industry",
                            message:
                                "Please select an industry",
                        })
                    }

                    if (values.industrytime === "") {
                        errors.push({
                            field: "industrytime",
                            message:
                                "Please select how long have you been in the industry",
                        })
                    }

                    if (values.userplus18yearsold === "") {
                        errors.push({
                            field: "userplus18yearsold",
                            message:
                                "Please select if you are over 18 years old",
                        })
                    }

                    return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                    if (values.industry !== "") {
                        const optSubject =
                            "Confirming your Subscription to the Techs and Trades Email List"
                        const optMessage = `Thanks for joining the Techs and Trades newsletter! Expect some epic industry content, product tips, special offers and more coming your way every month.\n \nTo confirm your subscription, please click this link or paste it into your browser:\n \n[opt_in_url] \n \nTo ensure proper delivery of our future emails, take a moment now and add our email address - [rsvp_email] - to your address book, trusted sender list, or company white list. \n \nif you'd like to update your email preferences, use this link:\n \n[opt_in_url] \n \nIf you do not wish to receive our emails, or this email has reached you in error, please click or copy and paste this link in your browser window to remove yourself from our list:\n \n[opt_out_url] \n \nThanks, and welcome!\n[rsvp_name]\n`

                        const data = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            phone: values.phone,
                            lists: [26126285], // 26126285 --> Techs and Trades Program Group ID from Emma Service
                            industry: values.industry,
                            industrytime: values.industrytime,
                            birthday: undefined,
                            userplus18yearsold: values.userplus18yearsold,
                            confirmation: true,
                            optSubject,
                            optMessage,
                            signupFormID: 1930094,
                            formIdentifier: 'techstrades'
                        }
                        signUp(data).then(result => {
                            setSubmitting(false)
                            setShowConfirmation(true)
                        })
                    } else {
                        setSubmitting(false)
                        setShowConfirmation(false)
                    }
                }}
            >
                {props => (
                    <form
                        className={styles}
                        onSubmit={props.handleSubmit}
                    >
                        <div
                            className={
                                styles.techAndTradeMainInputs
                            }
                        >
                            <div
                                className={
                                    styles.techAndTradeInputs
                                }
                            >
                                <Input
                                    name="firstName"
                                    placeholder="First Name*"
                                    className={
                                        styles.techATradeInput
                                    }
                                    inputContainerClassName={
                                        styles.formTechST
                                    }
                                    onChange={
                                        props.handleChange
                                    }
                                    onBlur={props.handleBlur}
                                    value={
                                        props.values.firstName
                                    }
                                />
                                <Input
                                    name="lastName"
                                    placeholder="Last Name*"
                                    className={
                                        styles.techATradeInput
                                    }
                                    inputContainerClassName={
                                        styles.formTechST
                                    }
                                    onChange={
                                        props.handleChange
                                    }
                                    onBlur={props.handleBlur}
                                    value={
                                        props.values.lastName
                                    }
                                />
                            </div>
                            <div
                                className={
                                    styles.techAndTradeInputs
                                }
                            >
                                <Input
                                    name="email"
                                    placeholder="Email*"
                                    className={
                                        styles.techATradeInput
                                    }
                                    inputContainerClassName={
                                        styles.formTechST
                                    }
                                    onChange={
                                        props.handleChange
                                    }
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                />
                                <Input
                                    name="phone"
                                    phone="true"
                                    placeholder="(000) 000-0000"
                                    className={
                                        styles.techATradeInput
                                    }
                                    inputContainerClassName={
                                        styles.formTechST
                                    }
                                    onChange={
                                        props.handleChange
                                    }
                                    onBlur={props.handleBlur}
                                    value={
                                        props.values.phone
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div
                                className={
                                    styles.techAndTradeRadios
                                }
                            >
                                <div
                                    className="col-sm-6"
                                    style={{
                                        paddingLeft: "0px",
                                        textAlign: "left",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <Title
                                        value="Which Industry focus describes you?*"
                                        className={
                                            styles.titleRadioB
                                        }
                                    />
                                    <RadioButton
                                        name="industry"
                                        value="Automotive and Transportation"
                                        text="Automotive and Transportation"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industry"
                                        value="Construction and Skilled Trades"
                                        text="Construction and Skilled Trades"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industry"
                                        value="Industrial"
                                        text="Industrial"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industry"
                                        value="Agricultural"
                                        text="Agricultural"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                </div>

                                <div
                                    className="col-sm-6"
                                    style={{
                                        paddingLeft: "0px",
                                        textAlign: "left",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <Title
                                        value="How long have you been in the industry?*"
                                        className={
                                            styles.titleRadioB
                                        }
                                    />

                                    <RadioButton
                                        name="industrytime"
                                        value="0 - 5 Years"
                                        text="0 - 5 Years"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industrytime"
                                        value="6 - 10 Years"
                                        text="6 - 10 Years"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industrytime"
                                        value="11 - 15 Years"
                                        text="11 - 15 Years"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industrytime"
                                        value="16 - 20 Years"
                                        text="16 - 20 Years"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="industrytime"
                                        value="20+ Years"
                                        text="20+ Years"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                </div>
                                <div
                                    className="col-sm-6"
                                    style={{
                                        paddingLeft: "0px",
                                        textAlign: "left",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <Title
                                        value="Are you 18 years or older?*"
                                        className={
                                            styles.titleRadioB
                                        }
                                    />

                                    <RadioButton
                                        name="userplus18yearsold"
                                        value="yes"
                                        text="Yes"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                    <RadioButton
                                        name="userplus18yearsold"
                                        value="no"
                                        text="No"
                                        onChange={
                                            props.handleChange
                                        }
                                    ></RadioButton>
                                </div>
                                <div
                                    className="col-sm-12"
                                    style={{
                                        paddingLeft: "0px",
                                        textAlign: "left",
                                        marginTop: "2rem",
                                    }}
                                >
                                    <div
                                        className={cn(
                                            styles.feedback
                                        )}
                                    >
                                        <Text
                                            value="Required Information*"
                                            className={
                                                styles.requiredText
                                            }
                                        />
                                        <ul>
                                            {_isArray(
                                                props.errors
                                            ) &&
                                                props.errors
                                                    .filter(
                                                        f => {
                                                            return props
                                                                .touched[
                                                                f
                                                                    .field
                                                            ]
                                                        }
                                                    )
                                                    .map(e => {
                                                        return (
                                                            <li
                                                                key={
                                                                    e.field
                                                                }
                                                            >
                                                                {
                                                                    e.message
                                                                }
                                                            </li>
                                                        )
                                                    })}
                                        </ul>
                                    </div>
                                </div>
                                <Button
                                    text="Join the Techs and Trades Program"
                                    blue
                                    submit
                                    className={styles.redButton}
                                />
                            </div>
                        </div>
                    </form>
                )
                }
            </Formik>
        </div>
    </div>
)

export default TechAndTradeProgram
